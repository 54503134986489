import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSaleCloseSaleList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'name', label: 'Society Name', sortable: true },
        { key: 'market', label: 'Market', sortable: false },
        { key: 'number', label: 'Sale #', sortable: false },
        { key: 'issued', label: 'Issued Tickets', sortable: false },
        { key: 'bought', label: 'Bought', sortable: false },
        { key: 'mass', label: 'KGs', sortable: false },
        { key: 'value', label: 'Value', sortable: false },
        { key: 'rejects', label: 'Rejects', sortable: false },
        { key: 'cancelled', label: 'Cancelled', sortable: false },
        { key: 'withdrawn', label: 'Withdrawn', sortable: false },
        { key: 'unused', label: 'Unused Tickets', sortable: false },
        'actions',
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)
    const location_id = ref(null)
    const market_id = ref(null)
    const sale_id = ref(null)
    const society_id = ref(null)

    const refetch = () => {
        records.value.refresh()
    }

    watch([location_id, market_id, sale_id, currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store.dispatch('cromis-sale_close/list', {
            location_id: location_id.value,
            market_id: market_id.value,
            sale_id: sale_id.value,
            query: searchQuery.value,
            per_page: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        }).then(response => {
            const { societies } = response.data

            callback(societies)
            totalRecords.value = societies.length
            from.value = 1
            to.value = 1
        }).catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching requisitions list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }


    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*


    const resolveStatusVariant = approved => {
        if (approved === true) return 'success'
        if (approved === false) return 'secondary'
        return 'warning'
    }


    return {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
        resolveStatusVariant,
        refetch,
        location_id,
        market_id,
        sale_id,
        society_id,
    }
}
