<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="6">
                        <b-row>
                            <b-col cols="12" md="12">
                                <location-filters
                                    size="sm"
                                    align="horizontal"
                                    @filter="changeLocation"
                                />
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" md="3">
                        <b-form-group label-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select
                                    id="market_id"
                                    name="market_id"
                                    size="sm"
                                    v-model="market_id"
                                    @change="changeMarket()"
                                >
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }} ({{ market.code.toUpperCase() }})
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select
                                    id="sale_id"
                                    name="sale_id"
                                    size="sm"
                                    v-model="sale_id"
                                >
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale.id">{{ sale.number }}</b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{ serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm"
                        />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(name)="data">
                    {{ data.item.code.toUpperCase() }}: {{ data.item.name.toUpperCase() }}
                </template>

                <template #cell(market)="data">
                    {{ data.item.market.code.toUpperCase() }}: {{ data.item.market.name.toUpperCase() }}
                </template>

                <template #cell(number)="data">
                    {{ data.item.number }}
                </template>

                <template #cell(issued)="data">
                    {{ data.item.issued.toLocaleString() }}
                </template>

                <template #cell(bought)="data">
                    {{ data.item.bought.toLocaleString() }}
                </template>

                <template #cell(mass)="data">
                    {{ data.item.mass.toLocaleString() }}
                </template>

                <template #cell(value)="data">
                    {{ data.item.value.toLocaleString() }}
                </template>

                <template #cell(rejects)="data">
                    {{ data.item.rejects.toLocaleString() }}
                </template>

                <template #cell(cancelled)="data">
                    {{ data.item.cancelled.toLocaleString() }}
                </template>

                <template #cell(withdrawn)="data">
                    {{ data.item.withdrawn.toLocaleString() }}
                </template>

                <template #cell(unused)="data">
                    {{ data.item.unused.toLocaleString() }}
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <span v-if="!data.item.closed" title="Close sale" @click="changeSaleStatus(data.item, true)">
                        <b-avatar variant="light-info" class="mr-1">
                            <feather-icon
                                icon="UnlockIcon"
                                size="16"
                            />
                        </b-avatar>
                    </span>
                    <span v-if="data.item.closed" title="Sale closed">
                        <b-avatar variant="light-secondary" class="mr-1">
                            <feather-icon
                                icon="LockIcon"
                                size="16"
                            />
                        </b-avatar>
                    </span>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker,
    BAvatar,
} from 'bootstrap-vue'
import LocationFilters from '@core/components/cromis/filters/LocationFilters.vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'
import saleCloseStoreModule from '@/views/cromis/sales/sale_close/saleCloseStoreModule'
import useSaleCloseList from '@/views/cromis/sales/sale_close/useSaleCloseList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner, BAvatar,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, BFormDatepicker,
        LocationFilters,
    },
    directives: {},
    watch: {
    },

    setup(props, context) {
        const dataForm = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)

        const markets = ref([])
        const sales = ref([])
        const tikets = ref([])


        const CROMIS_STORE_MODULE_NAME = 'cromis-sale_close'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, saleCloseStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-sale_close/markets')
                .then(response => {
                    markets.value = response.data.markets
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            location_id,
            market_id,
            sale_id,
            society_id,
        } = useSaleCloseList()

        // Form Methods
        const changeLocation = async (location) => {
            location_id.value = location != null? location.id : null

            await store.dispatch('cromis-sale_close/markets', { location_id: location_id.value })
                .then(response => {
                    markets.value = response.data.markets
                    market_id.value = null
                    sale_id.value = null
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeMarket = async () => {
            await store.dispatch('cromis-sale_close/sales', { market_id: market_id.value })
                .then(response => {
                    sales.value = response.data.sales
                    sale_id.value = null
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }
 
        const changeSaleStatus = async (item, close) => {
            const result =  await context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: close? 'Are you sure you want to Close Sale for '+item.name+'?' : 'Are you sure you want to reverse sale for '+item.name+'?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, Close it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2',
                },
                buttonsStyling: true,
            }).then((result) => {
                if (result.value) {
                    store.dispatch('cromis-sale_close/create', { society_id: item.id, sale_id: item.sale_id, close: close })
                        .then(response => {
                            refetch()

                            context.root.$swal({
                                icon: 'success',
                                text: `Sale for selected Society is closed successfully!`,
                                showConfirmButton: true,
                                timer: 3000,
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                        })
                        .catch(error => {
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: true,
                            })
                        })
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Sale is safe you cancell close of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        return {
            // Data

            // Methods
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            required,
            saving,
            serverErrors,
            markets,
            sales,

            location_id,
            market_id,
            sale_id,
            society_id,

            changeLocation,
            changeMarket,
            changeSaleStatus,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
